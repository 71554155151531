<template>
  <aside class="root">
    <BaseSidebar
      :title="$t('組織設定')"
      :menus="[
        {
          to: { name: 'organizationId-edit' },
          text: $t('情報'),
        },
        {
          to: { name: 'organizationId-locations' },
          text: $t('拠点'),
        },
        {
          to: { name: 'organizationId-users' },
          text: $t('管理者'),
        },
      ]"
    />
  </aside>
</template>

<style scoped lang="scss">
.root {
  width: 260px;
}
</style>
